@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom fallback if gradient doesn't render well */
body {
  font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  padding: 0 !important;
  margin: 0 !important;
}
